import React, { useState, useEffect, memo, useContext, useRef } from "react";
import { Avatar } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getItem, setItem, deleteItem } from "../utils/storage";
import { ToastContainer, toast } from "react-toastify";
import { getReqParamheader } from "../services/apiCall";
import { API_URL_CONSTANT } from "../constants/apiConstant";
import { OrgContext } from "../OrgContext";
import CustomButton from "./CustomButton";
import { LogIn } from "lucide-react";

const initial = [
  {
    name: "Create an Organization",
    url: "/create-org",
  },
  {
    name: "Profile Setting",
    url: "/user/setting",
  },
];

function Navbar() {
  const [token, setToken] = useState(false);
  const [card, setCard] = useState(false);
  const [cardData, setCardData] = useState([]);
  const [orgData, setOrg] = useState([]);
  const [userId, setUserId] = useState("");
  const [user, setUser] = useState({});
  const loc = useLocation().pathname;
  const navigate = useNavigate();
  const { organizationId, setOrganizationId } = useContext(OrgContext);

  const cardRef = useRef(null);

  const currentOrgId = loc.split("/")[2];

  useEffect(() => {
    let t = CheckAuth();
    const id = getItem("user_id");
    if (t && id) {
      setToken(t);
      setUserId(id);
    }
  }, [getItem("token"), token]);

  useEffect(() => {
    if (userId) {
      getUserData();
    }
  }, [userId, getItem("user")]);

  useEffect(() => {
    getCardData();
  }, [user]);

  const getCardData = () => {
    let org = user?.Organizationlist || [];
    setOrg(org);
    setCardData(initial);
  };

  const getUserData = () => {
    if (userId && userId.length > 0) {
      getUserById(userId);
    }
  };

  const getUserById = (id) => {
    let url = `${API_URL_CONSTANT.baseUrl}/user/${id}`;
    getReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          setItem("user", JSON.stringify(res?.data?.data));
          setItem("currentuser", res?.data?.data?.firstName);
          setUser(res?.data?.data);
        } else {
          logout();
        }
      })
      .catch((err) => {
        toast.error("User not identified. Please relogin.");
        logout();
      });
  };

  const CheckAuth = () => {
    const session = getItem("token");
    if (session) {
      return true;
    }
    return false;
  };

  const logout = () => {
    deleteItem("token");
    toggleCard();
    CheckAuth();
    setToken(false);
    deleteItem("user_id");
    deleteItem("currentOrg");
    deleteItem("user");
    deleteItem("org_id");
    deleteItem("currentuser");
    setUserId("");
    navigate("/");
  };

  const toggleCard = (event) => {
    event?.stopPropagation();
    setCard((prevCard) => !prevCard);
  };

  const handleStore = (item) => {
    setItem("currentOrg", item?.org_name);
    setOrganizationId(item?.orgId);
    toggleCard(); // Close the card after selecting an organization
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (card && cardRef.current && !cardRef.current.contains(event.target)) {
        setCard(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [card]);

  return (
    <div className="w-full px-4 lg:px-8 py-2 z-10 bg-white">
      <div className="flex justify-between items-center">
        <Link to="/">
          <img
            src={process.env.PUBLIC_URL + "/images/smorgborg-logo.png"}
            className="w-28 lg:w-[138px]"
            alt="logo"
          />
        </Link>
        {!token ? (
          <>
            {loc !== "/login" && loc !== "/signup" && (
              <CustomButton
                icon={<LogIn size={20} />}
                content={"Login"}
                bgColor={"bg-blue-600 "}
                clickHandler={() => navigate("/login")}
                additionalStyles={"text-white hover:bg-blue-700"}
              />
            )}
          </>
        ) : (
          <>
            <div className="relative" onClick={(e) => toggleCard(e)}>
              {user?.profile_photo ? (
                <Avatar
                  className="cursor-pointer"
                  src={user?.profile_photo}
                  alt="profile"
                  size="large"
                />
              ) : (
                <div className="bg-blue-200 text-lg lg:text-xl font-bold border-2 border-blue-700 text-blue-700 w-8 lg:w-10 h-8 lg:h-10 flex justify-center items-center rounded-full cursor-pointer">
                  <p>{user?.firstName?.split("")[0].toUpperCase() || "U"}</p>
                </div>
              )}
            </div>

            {card && (
              <div
                ref={cardRef}
                className="absolute top-10 right-10 z-20 w-[250px]"
              >
                <div className="text-white bg-[#001529] my-2 border rounded-md p-2">
                  <div className="border-b pb-2 mb-2 divide-y">
                    <div className="overflow-y-auto max-h-[60vh]">
                      {orgData?.length > 0 &&
                        orgData.map((item, i) => (
                          <div
                            key={i}
                            onClick={() => {
                              handleStore(item);
                            }}
                            className="flex flex-col gap-2"
                          >
                            <Link to={"/manage/" + item?.orgId}>
                              <div
                                className={`transition-all cursor-pointer text-white px-4 py-2 mb-1 text-sm rounded-sm font-medium ${
                                  currentOrgId === item?.orgId
                                    ? "bg-blue-600"
                                    : "hover:bg-gray-700"
                                }`}
                              >
                                {item?.org_name}
                              </div>
                            </Link>
                          </div>
                        ))}
                    </div>
                  </div>
                  {cardData.map((item, i) => (
                    <div key={i} className="">
                      <Link to={item.url}>
                        <div
                          className="cursor-pointer hover:bg-gray-700 text-white px-4 py-2 font-medium text-sm"
                          onClick={toggleCard}
                        >
                          {item?.name}
                        </div>
                      </Link>
                    </div>
                  ))}
                  <div className="mt-2 border-t border-white">
                    <button
                      className="cursor-pointer mt-2 transition-all hover:bg-red-700 text-white px-4 py-1.5 w-[232px] border-2 border-red-700 rounded-sm text-sm font-medium"
                      onClick={logout}
                    >
                      Log Out
                    </button>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default memo(Navbar);
