import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { API_URL_CONSTANT } from "../../constants/apiConstant";
import { getReqParamheader } from "../../services/apiCall";
import { getItem } from "../../utils/storage";
import { Breadcrumb } from "antd";
import { Helmet } from "react-helmet";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
} from "react-share";
import BaseBackButton from "../../components/BaseBackButton";
import { toast } from "react-toastify";
import PageSnipper from "../../components/PageSnipper";

const MetaTags = ({ title, description, image, url }) => (
  <Helmet>
    <title>{title}</title>
    <meta charSet="utf-8" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, shrink-to-fit=no"
    />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:image" content={image} />
    <meta property="og:url" content={url} />
    <meta property="og:type" content="website" />
    <meta property="og:site_name" content="Smorgborg" />
    <meta property="twitter:card" content="summary_large_image" />
    <meta property="twitter:title" content={title} />
    <meta property="twitter:description" content={description} />
    <meta property="twitter:image" content={image} />
  </Helmet>
);

const BreadcrumbComponent = ({ navigateToArticleList, title }) => (
  <Breadcrumb
    items={[
      { title: <button onClick={navigateToArticleList}>Articles</button> },
      { title },
    ]}
  />
);

function View() {
  const [articleId, setArticleId] = useState("");
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState("");
  const [htmlToReactParser, setHtmlToReactParser] = useState(null);

  const path = useLocation().pathname;
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const currentOrgId = getItem("org_id");

  const orgName = searchParams.get("org");
  const des = htmlToReactParser
    ? htmlToReactParser.parse(data?.draft?.Content)
    : null;

  useEffect(() => {
    // Dynamically import html-to-react for lazy loading
    import("html-to-react").then(({ Parser }) => {
      setHtmlToReactParser(new Parser());
    });
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    const article = path.split("/")[2];
    setArticleId(article);
  }, [path]);

  useEffect(() => {
    if (articleId) fetchArticleData(articleId);
  }, [articleId]);

  const fetchArticleData = async (id) => {
    setLoading(true);
    const url = `${API_URL_CONSTANT.baseUrl}/article_details/${id}`;
    try {
      const res = await getReqParamheader(url);
      if (res?.status) {
        const articleData = res?.data?.data;
        const contentPreview = articleData?.draft?.Content.replace(
          /<[^>]*>?/gm,
          ""
        ).substring(0, 100);
        setDescription(contentPreview);
        setData(articleData);
      } else {
        console.error("Response error:", res?.message);
        toast.error(res?.message || "Failed to fetch article data.");
      }
    } catch (err) {
      console.error("Fetch error:", err);
      toast.error(
        err?.response?.data?.message ||
          "An error occurred while fetching the article."
      );
    } finally {
      setLoading(false);
    }
  };

  const navigateToArticleList = () =>
    navigate(`/manage/${currentOrgId}/article`);
  const handleOrgNavigation = () => navigate(`/manage/${currentOrgId}`);
  const formattedDate = new Date(data?.publishedAt).toLocaleString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  return (
    <div className="flex justify-center min-h-screen bg-gray-100">
      {loading ? (
        <PageSnipper loading={loading} />
      ) : (
        <div
          className="w-[50%] px-8 py-4 mt-4 bg-white"
          style={{
            boxShadow:
              "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset",
            marginBottom: "3%",
          }}
        >
          <MetaTags
            title={data?.draft?.Title || "Article Title"}
            description={description || "Article description"}
            image={data?.draft?.Featured_Photo || "default-image-url"}
            url={`https://smorgborg.org${path}`}
          />

          <BreadcrumbComponent
            navigateToArticleList={navigateToArticleList}
            title={data?.draft?.Title}
          />

          <div className="w-full mt-0 flex justify-center">
            {data?.draft?.Featured_Photo && (
              <img
                src={data?.draft?.Featured_Photo}
                className="max-h-[600px] max-w-[600px] px-4 py-4"
                alt="Article Featured"
              />
            )}
          </div>

          <h1 className="font-bold text-lg text-gray-700">
            {data?.draft?.Title}
          </h1>
          <div className="flex justify-between items-start mt-2">
            <div className="text-gray-600 text-1xl">
              <p
                className="text-gray-600 cursor-pointer hover:text-blue-800"
                onClick={handleOrgNavigation}
              >
                {orgName}
              </p>
              {formattedDate}
            </div>

            <div className="text-gray-600 text-1xl flex items-center gap-3">
              Share
              <FacebookShareButton url={`https://smorgborg.org${path}`}>
                <FacebookIcon size={32} />
              </FacebookShareButton>
              <TwitterShareButton url={`https://smorgborg.org${path}`}>
                <TwitterIcon size={32} />
              </TwitterShareButton>
            </div>
          </div>

          <hr style={{ marginTop: "4px" }} />
          <div className="w-full mt-2 mb-4 des-container">{des}</div>
          <BaseBackButton
            type="button"
            text="Go Back to Article List"
            clickHandler={navigateToArticleList}
          />
        </div>
      )}
    </div>
  );
}

export default View;
