import React from "react";

function EventDigest({ data, hs, hf, ts, tf }) {
  const HtmlToReactParser = require("html-to-react").Parser;
  const htmlToReactParser = new HtmlToReactParser();
  //let ed = htmlToReactParser.parse(data?.Event_Description);
  // let url = `https://smorgborg.org/view/${id}`

  var dateOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  return (
    <>
      {
        <div style={{ textAlign: "start" }}>
          <table
            border="0"
            cellSpacing="0"
            width="100%"
            style={{
              marginTop: "8px",
              marginBottom: "8px",
              borderCollapse: "separate",
              borderSpacing: "1em",
            }}
          >
            <tbody>
              {data.length > 0 ? (
                data.map((event) => (
                  <tr width="600" style={{}}>
                    <td width="600" align="left" style={{ display: "block" }}>
                      <div>
                        <div
                          style={{
                            fontSize: "14px",
                            fontFamily: `${tf}`,
                            margin: "0",
                            padding: "0",
                          }}
                        >
                          {
                            event?.Event_Start_Date.toLocaleString(
                              "en-US",
                              dateOptions
                            ).split(" ")[0]
                          }
                        </div>
                        <div
                          style={{
                            fontSize: `${hs}`,
                            fontFamily: `${hf}`,
                            margin: "0",
                            padding: "0",
                          }}
                        >
                          {event?.Event_Website !== "" ? (
                            <a target="_blank" href={event?.Event_Website}>
                              {event?.Event_Name}
                            </a>
                          ) : (
                            <div>{event?.Event_Name}</div>
                          )}
                        </div>
                        <div
                          style={{
                            fontSize: "13px",
                            fontFamily: `${tf}`,
                            color: "darkgreen",
                            margin: "0",
                            padding: "0",
                          }}
                        >
                          <em>BY:</em> <b>{event?.Event_Organizer}</b>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <div>
                  Trying to fetch Events for the duration you chose. Currently
                  no events.
                </div>
              )}
            </tbody>
          </table>
        </div>
      }
    </>
  );
}

export default EventDigest;
